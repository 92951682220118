import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import Logo from "../../assets/Logo.png";

const Navigation = ({ links }) => {
  const responsive = () => {
    if (typeof window !== "undefined" && window.innerWidth < 700) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='nav-container'>
      <div className='wrapper'>
        <Link style={{ paddingTop: ".75rem" }} to='/'>
          <img className='nav-logo' src={Logo} />
        </Link>

        <ul style={{ display: "flex", listStyle: "none", padding: "1rem 0" }}>
          {responsive() ? (
            <Menu right width='280px'>
              <Link style={{ textDecoration: "none" }} to='/financing'>
                Financing
              </Link>
              <Link style={{ textDecoration: "none" }} to='/faqs'>
                FAQs
              </Link>
              <Link style={{ textDecoration: "none" }} to='/blog'>
                Blog
              </Link>
              <Link style={{ textDecoration: "none" }} to='/contact'>
                <button className='cta cta-main' style={{ padding: ".75rem 1.5rem", fontSize: "18px" }}>
                  Contact Us
                </button>
              </Link>
            </Menu>
          ) : (
            links
              .filter((item) => item.title !== "Home")
              .map((item) => {
                const { slug, title } = item;
                if (item.title === "Contact") {
                  return (
                    <Link style={{ textDecoration: "none", paddingTop: ".5rem" }} to='/contact'>
                      <button className='cta cta-main' style={{ padding: ".75rem 1.5rem", fontSize: "18px" }}>
                        Contact Us
                      </button>
                    </Link>
                  );
                } else {
                  return (
                    <li style={{ padding: "1rem 2rem" }}>
                      <Link style={{ textDecoration: "none", fontSize: "20px" }} to={slug}>
                        {title}
                      </Link>
                    </li>
                  );
                }
              })
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
