import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer>
      <div>
        <ul>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/financing'>Financing</Link>
          </li>
          <li>
            <Link to='/faqs'>FAQs</Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to='/blog'>
              Blog
            </Link>
          </li>
          <li>
            <Link to='/contact'>Contact</Link>
          </li>
        </ul>
        <hr />
        <p
          style={{
            width: "100%",
            fontSize: "14px",
            maxWidth: "700px",
            marginBottom: "2rem",
          }}
        >
          {/* Interest rates and products are subject to change without notice and may or may not be available at the time of loan commitment or
          lock-in. Borrowers must qualify at closing for all benefits. */}
        </p>
        <p>© 2022 - Developed by Gavin Carter</p>
      </div>
    </footer>
  );
};

export default Footer;
