import React from "react";
import "../../allSass/base.css";
import { Link } from "gatsby";
import Footer from "./footer";
import { slide as Menu } from "react-burger-menu";
import Navigation from "./Navigation";

const Template = ({ children }) => {
  const links = [
    { slug: "/", title: "Home" },
    { slug: "/financing", title: "Financing" },
    { slug: "/faqs", title: "FAQs" },
    { slug: "/blog", title: "Blog" },
    { slug: "/contact", title: "Contact" },
  ];

  return (
    <div>
      <Navigation links={links} />

      {/* <nav role="navigation">
        <ul className="navigation">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blog/">Blog</Link>
          </li>
        </ul>
      </nav> */}

      {children}
      <Footer />
    </div>
  );
};

export default Template;
